import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { FORGOT_PASSWORD, LOGIN_USER, LOGOUT_USER, ONBOARD_USER, REGISTER_USER, RESET_PASSWORD } from "./actionTypes"

import axios from 'axios';
import { getCurrentUser, setCurrentUser } from "../../helpers/util";
import { toast } from "sonner";
import { forgotPasswordError, forgotPasswordSuccess, loginUserError, loginUserSuccess, onboardUserError, registerUserError, registerUserSuccess, resetPasswordError, resetPasswordSuccess } from "./actions";

const authEndpoint = process.env.REACT_APP_AUTHENDPOINT;


export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  const { data } = await axios.post(`${authEndpoint}rest-auth/login/`, {
    email,
    password,
  });
  // firebaseUtils.logEvent('login_success', { email, password }, data);

  return data;
};

const decodeToken = async (token) => {
  const { data } = await axios({
    url: `${authEndpoint}users/me/?timestamp=${new Date().getTime()}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${token}`,
    },
  });

  return data;
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const tokenUser = yield call(loginWithEmailPasswordAsync, email, password);

    if (tokenUser.token) {
      const loginUser = yield call(decodeToken, tokenUser.token);

      if (loginUser) {
        const item = {
          ...loginUser,
          id: loginUser.id,
          email: loginUser.email,
          username: loginUser.username,
          photo: loginUser.photo,
          token: tokenUser.token,
          date: '',
          role: 1,
          onboarded: loginUser.onboarded,
          first_name: loginUser.first_name,
          last_name: loginUser.last_name,
          phone_number: loginUser.phone_number,
          phone_number_verified: loginUser.phone_number_verified,
          is_savrbvn_verified: loginUser.is_savrbvn_verified,
          is_kyc_submitted: loginUser.is_kyc_submitted,
          is_email_verified: loginUser.is_email_verified,
          is_kyc_verified: loginUser.is_kyc_verified,
          halal: loginUser.halal,
        };
        if (loginUser.savr_group) {
          setCurrentUser(item);
          yield put(loginUserSuccess(item));
          toast.success("Login Successful")
          const route = localStorage.getItem('pending_route') || '/app';
          
          localStorage.removeItem('pending_route');
          history.push(route);
        }
        else {
          toast.error("Unauthorized!", { description: "You are not onboarded on Savr" })
          yield put(loginUserError(tokenUser.data));
        }

      }
    } else {
      // toast(Object.values(tokenUser.data)[0][0]);
      toast.error("Error", { description: Object.values(tokenUser.data)[0][0] })
      yield put(loginUserError(tokenUser.data));
    }
  } catch (error) {
    // firebaseUtils.logEvent('login_failed', {
    //   error: error?.response?.data || error?.message,
    //   body: { email, password },
    // });
    if (error?.response?.status === 400) {
      toast.error("Login Error", { description: Object.values(error?.response?.data)[0][0] })
    } else {
      toast.error('Unable to login');
    }
    yield put(loginUserError(error?.response?.data));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (
  first_name,
  last_name,
  email,
  password1,
  password2,
  agent_referral_code,
  user_referral_code
) =>
  axios
    .post(`${authEndpoint}rest-auth/registration/`, {
      first_name,
      last_name,
      email,
      password1,
      password2,
      agent_referral_code,
      user_referral_code,
    })
    .then((response) => {
      // firebaseUtils.logEvent(
      //   'registration_success',
      //   {
      //     first_name,
      //     last_name,
      //     email,
      //     password1,
      //     password2,
      //     agent_referral_code,
      //     user_referral_code,
      //   },
      //   response.data
      // );
      return response.data;
    })
    .catch((error) => {
      // firebaseUtils.logEvent('registration_failed', {
      //   error: error?.response?.data || error?.message,
      //   body: {
      //     first_name,
      //     last_name,
      //     email,
      //     password1,
      //     password2,
      //     agent_referral_code,
      //     user_referral_code,
      //   },
      // });
      return error.response;
    });

function* registerWithEmailPassword({ payload }) {
  const {
    first_name,
    last_name,
    email,
    password1,
    password2,
    agent_referral_code,
    user_referral_code,
  } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      first_name,
      last_name,
      email,
      password1,
      password2,
      agent_referral_code,
      user_referral_code
    );
    if (registerUser.user && registerUser.token) {
      const item = {
        id: registerUser.user.id,
        email: registerUser.user.email,
        username: registerUser.user.username,
        photo: registerUser.user.photo,
        date: '',
        role: 1,
        token: registerUser.token,
        onboarded: registerUser.user.onboarded,
        phone_number: registerUser.user.phone_number,
        first_name: registerUser.user.first_name,
        last_name: registerUser.user.last_name,
      };
      setCurrentUser(item);

      yield put(registerUserSuccess(item));

      history.push('/onboard');
    } else {
      yield put(registerUserError(registerUser.data));
    }
  } catch (error) {
    yield put(registerUserError(error.response.data));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  history.push('/user');
};

function* logout({ payload }) {
  const { history } = payload;

  setCurrentUser(null);
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) =>
  // eslint-disable-next-line no-return-await

  axios
    .post(`${authEndpoint}accounts/password_reset_request/`, { email })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  const { history } = payload;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (forgotPasswordStatus.detail) {
      yield put(forgotPasswordSuccess('success'));
      history.push('/admin/reset-password');
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

// const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
//   // eslint-disable-next-line no-return-await
//   return await auth
//     .confirmPasswordReset(resetPasswordCode, newPassword)
//     .then((user) => user)
//     .catch((error) => error);
// };

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

// export function* watchOnboardUser() {
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(ONBOARD_USER, onboardWithDetails);
// }

// const onboardWithDetailsAsync = async (token, id, fd) =>
//   axios({
//     url: `${authEndpoint}users/${id}/`,
//     method: 'put',
//     data: fd,
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `JWT ${token}`,
//     },
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => error.response);

// function* onboardWithDetails({ payload }) {
//   const {
//     token,
//     id,
//     nationality,
//     location,
//     date_of_birth,
//     job_title,
//     profession,
//     industry,
//     phone_number,
//   } = payload.user;
//   const fd = {
//     nationality,
//     location: location || 'location',
//     date_of_birth,
//     job_title: job_title || 'job title',
//     profession: profession || 'profession',
//     industry: industry || 'industry',
//     onboarded: false,
//     phone_number,
//   };
//   const { history } = payload;

//   try {
//     const onboardUser = yield call(onboardWithDetailsAsync, token, id, fd);
//     if (onboardUser.onboarded === false) {
//       const item = {
//         onboarded: onboardUser.onboarded,
//         is_savrbvn_verified: onboardUser.is_savrbvn_verified,
//         is_email_verified: onboardUser.is_email_verified,
//         is_kyc_submitted: onboardUser.is_kyc_submitted,
//         is_kyc_verified: onboardUser.is_kyc_verified,
//         phone_number: onboardUser.phone_number,
//         phone_number_verified: onboardUser.phone_number_verified,
//         has_performed_transaction: onboardUser.has_performed_transaction,
//       };

//       const currentUser = getCurrentUser();
//       const newUser = {
//         ...currentUser,
//         ...item,
//       };

//       setCurrentUser({ ...newUser });

//       yield put(onboardUserSuccess(newUser));

//       history.push({
//         pathname: onboarded4,
//         state: onboardUser.phone_number,
//       });
//     } else {
//       yield put(onboardUserError(onboardUser.data.message));
//     }
//   } catch (error) {
//     yield put(onboardUserError(error));
//   }
// }

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    // fork(watchOnboardUser),
  ]);
}